<template>
  <FullScreenDialog
    v-model="hairColorDialog"
    title="Nuovo Colore Di Capelli"
    :routeFather="pathToGoBack"
  >
    <template v-slot:toolbar-extension>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab
          v-for="tab in hairColorTabs"
          :key="tab.key"
        >{{ tab.title }}</v-tab>
      </v-tabs>
    </template>
    <template v-slot:content>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="tabItem in hairColorTabs" :key="tabItem.key">
          <v-card flat>
            <component :is="tabItem.component"></component>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <template v-slot:footer-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        color="error"
        @click="$router.push({path: pathToGoBack})"
      >Annulla</v-btn>
      <v-btn
        text
        color="default"
        :disabled="!formValid"
        @click="submitForm"
        :loading="loading"
      >Salva</v-btn>
    </template>
  </FullScreenDialog>
</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import GeneralForm from "@/components/areas/registries/hairColors/form/GeneralFormTab.vue"
import hairColorsService from '@/services/hairColors/hairColors.service.js'
import hairColorForm from '@/services/hairColors/hairColor.form.js'

export default {
  name: "HairColorsRegistryNewForm",
  components: {
    FullScreenDialog,
    GeneralForm
  },
  data: function() {
    return {
      tab: null,
      hairColorDialog: this.openDialog,
      hairColorTabs: [
        {
          key: 0,
          title: "Generale",
          name: "general",
          component: "GeneralForm"
        }
      ],
      formValid: false,
      hairColor: undefined,
      originalUrl: window.location.pathname,
      loading: false
    };
  },
  mounted() {
    this.changeTabBasedOnProp(this.tabName)

    let self = this
    hairColorForm.resetHairColor()
    hairColorForm.on('update', function(data) {
      self.hairColor = data.hairColor
    })

    hairColorForm.on('valid-change', function(data) {
      self.formValid = data
    })
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false
    },
    pathToGoBack: {
      type: String,
      default: "/registries"
    },
    tabName: {
      type: String,
      default: 'general'
    }
  },
  methods: {
    changeTabBasedOnProp(tabName) {
      for(let i = 0; i < this.hairColorTabs.length; i++) {
        if(this.hairColorTabs[i].name == tabName) {
          this.tab = i
          break
        }
      }
    },
    submitForm() {
      this.loading = true
      hairColorsService.create(this.hairColor).then((result) => {
        this.loading = false
        this.$router.push({path: this.pathToGoBack})
      })
    }
  },
  watch: {
    openDialog(newVal) {
      this.hairColorDialog = newVal;
    },
    tab(newVal) {
      try {
        window.history.pushState("Tab", "Tab", this.originalUrl + '?tabName=' + this.hairColorTabs[newVal].name);
      } catch(err) {
        console.log('try setting the tab name but something goes wrong')
      }
    }
  },
  computed: {
  }
};
</script>