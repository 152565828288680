import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'

class hairColorsService {


  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/registries/hairColors/list", {
          page: page,
          rowPerPage: rowPerPage,
          filters: filters
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        })
        .catch((err) => {
          reject("error during the call");
        });
    });
  }

  archive(hairColor) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!hairColor.id)
        reject('id undefined')
      
      axios.post(apiUrl + 'registries/hairColors/archive', hairColor).then((response) => {
        if (!response.success)
          reject(response.results)
        else
          resolve(response.results)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  create(hairColor) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/registries/hairColors/create", hairColor)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        })
        .catch((err) => {
          reject("error during the call");
        });
    });
  }

  update(hairColor) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/registries/hairColors/update", hairColor)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        })
        .catch((err) => {
          reject("error during the call");
        });
    });
  }

  delete(hairColor) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!hairColor.id) {
        reject("id undefined");
      }

      axios
        .get(apiUrl + "/registries/hairColors/delete", {
          id: hairColor.id,
        })
        .then((response) => {
          if (!response.success) {
            reject(response.results);
          } else {
            resolve(response);
          }
        });
    });
  }

  get(hairColorId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "/registries/hairColors/get", { id: hairColorId })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        });
    });
  }

  fields() {
    return Promise.resolve([
      // here the fields
      { text: "Nome", value: "name" },
    ]);
  }
}

export default new hairColorsService();
